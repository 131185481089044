import React from 'react';

function Loading() {
    return (
        <div className="loader-container">
            <div className="loader">Loading...</div>
        </div>
    );
}

export default Loading;